import React, { useState, useEffect } from 'react';
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

import NewsPreview from './newsPreview';

function newsList(props) {
  const { news, intl } = props;

  // State for the list
  const [list, setList] = useState([...news.slice(0, 5)]);

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(news.length > 5);

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < news.length;
      const nextResults = isMore
        ? news.slice(currentLength, currentLength + 6)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < news.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Animate Link arrow
  function animArrowIn(event) {
    event.target.classList.add('in');
  }

  function animArrowOut(event) {
    event.target.classList.remove('in');
    event.target.classList.add('out');

    setTimeout(function () {
      event.target.classList.remove('out');
    }, 1000);
  }

  return (
    <section className="container news-list pb-6">
      <div className="grid_news">
        {news?.map((actu, i) => (
          <NewsPreview
            slug={actu.node.seo.slug}
            visuelFile={actu.node.visuelMajeur.visuel}
            visuelDescription={actu.node.visuelMajeur.visuel?.description}
            date={actu.node.date}
            title={actu.node.titre}
            timeReading={actu.node.tempsDeLecture}
            isLarge={i === 0 ? true : false}
            key={i}
          />
        ))}
      </div>
      {hasMore ? (
        <div className="wrapper-button-more">
          <a
            className="link go-bottom"
            onClick={handleLoadMore}
            onMouseEnter={e => animArrowIn(e)}
            onMouseLeave={e => animArrowOut(e)}
            aria-label={intl.formatMessage({
              id: 'load_more_news_label',
            })}
          >
            <FormattedMessage id="load_more" />{' '}
            <span className="icon-arrow-down"></span>
          </a>
        </div>
      ) : (
        ''
      )}
    </section>
  );
}

export default injectIntl(newsList);

import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import NewsList from '../components/news/newsList';
import BackToTop from '../components/navigation/backToTop';

const pageNews = ({ data, intl }) => {
  const actusList = data.allContentfulPageActualite.edges;

  return (
    <Layout
      headerCssClass="bkg-color2"
      mainCssClass="page-news-list bkg-color2"
      activeMenuLink="news"
    >
      <SEO title="News" titleOnglet="News" pageLink="news" />

      {/* HeroTitle */}
      <HeroTitle title="News" />

      {/* NewsList */}
      <NewsList news={actusList} />

      {/* BackToTop */}
      <BackToTop />
    </Layout>
  );
};

export const query = graphql`
  query ContentfulPageActualite($language: String!) {
    allContentfulPageActualite(
      sort: { fields: publishedAt, order: DESC }
      filter: { node_locale: { eq: $language } }
    ) {
      edges {
        node {
          id
          seo {
            slug
          }
          titre
          date
          tempsDeLecture
          visuelMajeur {
            visuel {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              description
            }
          }
        }
      }
    }
  }
`;

export default injectIntl(pageNews);
